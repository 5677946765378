import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTreeNestedDataSource, MatTree, MatTreeNodeDef, MatNestedTreeNode, MatTreeNodeOutlet } from '@angular/material/tree';
import { ActivatedRoute, Params, Router } from '@angular/router';
// import { AuthenticationService } from '@conceptualpathways/cpoauth';
import { CategoryNode, ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { PunchOutIntegration } from '@idealsupply/ngclient-webservice-shopping-cart';
import { Vendor } from '@idealsupply/ngclient-webservices-vendors';
import { CartService, PunchOutIntegrationService, ProductPurchaseListComponent, SavedListSelectComponent } from 'lib-cart';
import { IProductListFilter, ProductListDataSource } from 'lib-products';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { AuthenticationService } from 'authentication-data';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatNavList, MatListItem } from '@angular/material/list';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { SectionComponent } from 'lib-common-components';
import { RenderInSecondaryNavigationDirective } from 'navigation-feature';

interface ISearchTerm {
  label: string;
  field: keyof IProductListFilter;
}

@Component({
  selector: 'feature-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatAccordion,
    NgTemplateOutlet,
    MatCardModule,
    NgIf,
    MatFormField,
    MatLabel,
    MatChipsModule,
    NgFor,
    MatIconButton,
    MatIcon,
    ProductPurchaseListComponent,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    ReactiveFormsModule,
    MatSlideToggle,
    MatInput,
    MatSelect,
    MatOption,
    MatButton,
    MatTree,
    MatTreeNodeDef,
    MatNestedTreeNode,
    NgClass,
    MatTreeNodeOutlet,
    MatNavList,
    MatListItem,
    AsyncPipe,
    SavedListSelectComponent,
    RenderInSecondaryNavigationDirective,
    SectionComponent,
  ],
  host: { class: 'legacy-page' },
})
export class ProductListComponent {
  public punchOutIntegrations$ = this.punchOutIntegrationService.integrations$;

  public vendors: Vendor[] = [];
  public productData?: ProductListDataSource;

  public categories$ = this.route.data.pipe(
    map((data) => data['categories'] as CategoryNode[]),
    tap((c) => {
      this.dataSource.data = c;
    }),
  );

  public treeControl: NestedTreeControl<CategoryNode>;
  public dataSource: MatTreeNestedDataSource<CategoryNode>;
  public searchTerms: ISearchTerm[] = [];
  public isLoading: boolean = false;
  private _productFilter?: BehaviorSubject<IProductListFilter>;

  public readonly hasCartId$ = this.cartService.idChange.pipe(
    startWith(undefined),
    map((v) => !!v),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public searchForm: FormGroup = new FormGroup({
    productAvailable: new FormControl(''),
    lineCode: new FormControl(''),
    partNumber: new FormControl(''),
    partDescription: new FormControl(''),
    partList: new FormControl(''),
    vendorNum: new FormControl(''),
  });

  public isAuthenticated$ = toObservable(this.auth.isAuthenticated);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeRef: ChangeDetectorRef,
    private readonly auth: AuthenticationService,
    private readonly cartService: CartService,
    private readonly punchOutIntegrationService: PunchOutIntegrationService,
  ) {
    route.data.subscribe((data) => {
      this.productData = data['products'];
      this.vendors = data['vendors'];
      setTimeout(() => {
        this.changeRef.markForCheck();
      });
    });

    this.treeControl = new NestedTreeControl((c) => {
      return c.children;
    });

    this.dataSource = new MatTreeNestedDataSource<CategoryNode>();

    route.queryParams.subscribe((q) => {
      if (!this._productFilter) {
        const qData = this.getFilterFromQuery(q);
        this._productFilter = new BehaviorSubject(qData);

        this.searchForm.patchValue(qData);
        this._productFilter.pipe(debounceTime(50)).subscribe((f) => {
          this.changeRef.markForCheck();
          this.cleanFilter(f);
          this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
              cat: f.category || undefined,
              lc: f.lineCode || undefined,
              desc: f.partDescription || undefined,
              pn: f.partNumber || undefined,
              pl: f.partList || undefined,
              ao: f.productAvailable || undefined,
              cat3: f.subCategory2 || undefined,
              cat2: f.subCategory || undefined,
              ven: f.vendorNum || undefined,
            },
          });
          this.generateSearchTerms();
        });
      } else {
        this.applyFilter();
        this.changeRef.markForCheck();
      }
    });
  }

  hasChild = (n: CategoryNode) => !!n.children?.length;
  isExpanded = (n: CategoryNode) => this.hasChild(n) && this.treeControl.isExpanded(n);
  isRoot = (n: CategoryNode) => !n.id?.length;

  clickedCategoryNode(node: CategoryNode) {
    if (this.hasChild(node)) {
      this.treeControl.toggle(node);
    } else {
      this.setCategory(node);
    }
  }

  setCategory(node: CategoryNode) {
    const filter = {} as IProductListFilter;
    filter.category = node.id?.[0];
    filter.subCategory = node.id?.[1];
    filter.subCategory2 = node.id?.[2];
    this.cleanFilter(filter);
    this._productFilter?.next(filter);
  }

  getSelectedState(node: CategoryNode): string {
    const selPath =
      [
        this._productFilter?.value.category ?? '',
        this._productFilter?.value.subCategory ?? '',
        this._productFilter?.value.subCategory2 ?? '',
      ]
        .filter((i) => !!i)
        .join('|') + '|';

    const nodePath = (node.id?.join('|') ?? '') + '|';

    if (nodePath === selPath) {
      return 'selected';
    } else if (selPath.indexOf(nodePath) === 0) {
      return 'child-selected';
    }
    return '';
  }

  private applyFilter() {
    const q = this.cleanFilter({ ...this.route.snapshot.queryParams });
    if (this.productData) {
      this.productData.filter = this.getFilterFromQuery(q);
      this.searchForm.patchValue(this.productData.filter);
      this.generateSearchTerms();
    }
  }

  private generateSearchTerms() {
    const filter = this.productData?.filter ?? ({} as IProductListFilter);
    this.searchTerms = [];
    if (filter.category) {
      this.searchTerms.push({
        label: `Category: "${filter.category}"`,
        field: 'category',
      });
    }
    if (filter.subCategory) {
      this.searchTerms.push({
        label: `Subcategory: "${filter.subCategory}"`,
        field: 'subCategory',
      });
    }
    if (filter.subCategory2) {
      this.searchTerms.push({
        label: `Subcategory 2: "${filter.subCategory2}"`,
        field: 'subCategory2',
      });
    }
    if (filter.productAvailable) {
      this.searchTerms.push({
        label: `Available Items Only`,
        field: 'productAvailable',
      });
    }

    if (filter.lineCode) {
      this.searchTerms.push({
        label: `Line code is: "${filter.lineCode}"`,
        field: 'lineCode',
      });
    }
    if (filter.partNumber) {
      this.searchTerms.push({
        label: `Part Number Contains: "${filter.partNumber}"`,
        field: 'partNumber',
      });
    }
    if (filter.partList) {
      this.searchTerms.push({
        label: `Part List is: "${filter.partList}"`,
        field: 'partList',
      });
    }
    if (filter.partDescription) {
      this.searchTerms.push({
        label: `Description Contains: "${filter.partDescription}"`,
        field: 'partDescription',
      });
    }
    if (filter.vendorNum) {
      const vendor = this.vendors.find((v) => v.id === filter.vendorNum);
      this.searchTerms.push({
        label: `Vendor is: "${vendor?.name} (${vendor?.id}")`,
        field: 'vendorNum',
      });
    }
  }

  private getFilterFromQuery(q: Params): IProductListFilter {
    return this.cleanFilter({
      category: q['cat'],
      lineCode: q['lc'],
      partDescription: q['desc'],
      partNumber: q['pn'],
      partList: q['pl'],
      productAvailable: q['ao'],
      subCategory2: q['cat3'],
      subCategory: q['cat2'],
      vendorNum: +q['ven'],
    });
  }

  private cleanFilter(data: IProductListFilter): IProductListFilter {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let val = (data as any)[key];
        val = typeof val === 'string' ? val.trim() : val;
        (data as any)[key] = val;
        if (val === '' || val === null || val === undefined || Number.isNaN(val)) {
          delete (data as any)[key];
        }
      }
    }
    return data;
  }

  public search() {
    const data = this.searchForm.getRawValue() as IProductListFilter;
    data.category = undefined; //this._productFilter?.value.category;
    data.subCategory = undefined; //this._productFilter?.value.subCategory;
    data.subCategory2 = undefined; //this._productFilter?.value.subCategory2;
    this.cleanFilter(data);
    this._productFilter?.next(data);
    this.searchForm.patchValue(data);
  }
  public clearSearch() {
    this.searchForm.reset({});
    this._productFilter?.next({});
  }

  public vendorDisplay = this._vendorDisplay.bind(this);

  private _vendorDisplay(vendorId: number): string {
    const vendor = this.vendors?.find((v) => v.id === +vendorId);
    return vendor?.name ?? '';
  }

  private _compareFilter(a?: IProductListFilter, b?: IProductListFilter): boolean {
    return JSON.stringify(this.cleanFilter(a ?? {})) === JSON.stringify(this.cleanFilter(b ?? {}));
  }

  private _isFilterEmpty(a?: IProductListFilter): boolean {
    return this._compareFilter(a, undefined);
  }

  public get isFilterEmpty(): boolean {
    return this._isFilterEmpty(this._productFilter?.value);
  }

  public get resultCount(): number {
    return this.productData?.count ?? 0;
  }

  public setLoading(loading: any): void {
    this.isLoading = loading;
    loading ? this.searchForm.disable() : this.searchForm.enable();
    this.changeRef.markForCheck();
    if (!loading) {
      document.scrollingElement?.scrollTo({ top: 0 });
    }
  }

  public handleTitleClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }> | any): void {
    this.router.navigate(['/products', event.detail.item.lineCode + event.detail.item.partNumber]);
    event.preventDefault();
  }

  public removeSearchTerm(term: ISearchTerm) {
    const data = this.searchForm.getRawValue() as IProductListFilter;
    data[term.field] = undefined;
    this.searchForm.reset(data);
    this._productFilter?.next(data);
  }

  public openPunchOut(poi: PunchOutIntegration) {
    this.punchOutIntegrationService.open(poi);
  }
}
